import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { imageUrl } from "../../lib";

const ManageProduct = () => {
  const dispatch = useDispatch();
  let { data = [] } = useSelector(
    (state) => state.product.listUserProducts
  );
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * itemsPerPage;
  const currentProducts = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_PRODUCTS",
      payload: {
        page: currentPage,
        limit: itemsPerPage,
      },
    });
  }, [dispatch, currentPage]);

  const deleteProduct = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa sản phẩm này?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Đóng",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: "DELETE_PRODUCT", payload: { id } });
      }
    });
  };

  function capitalizeFirstOnly(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const acceptStatusCheck = (string) => {
    switch (string) {
      case "accepted":
        return { text: "Đã duyệt", color: "success" };
      case "pending":
        return { text: "Chờ duyệt", color: "warning" };
      case "rejected":
        return { text: "Từ chối", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };

  return (
    <div className="row mt-1">
      <div className="d-flex justify-content-between">
        <h5 className="title-line-lt text-uppercase fw-bold">
          <span className="d-block">Danh sách sản phẩm</span>
        </h5>
        <Link to={"new"}>
          <div className="btn btn-primary">Tạo mới</div>
        </Link>
      </div>
      <Card className="p-0 mt-3">
        <Card.Body className="custom-card-body">
          <Table bordered className="m-0 mb-2">
            <thead>
              <tr>
                <th>Hình ảnh</th>
                <th>Tên sản phẩm</th>
                <th>Thông tin</th>
                <th>Tình trạng</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.length > 0 ? (
                currentProducts.map((product, index) => {
                  let product_image =
                    product.images[0] !== undefined
                      ? imageUrl(product.images[0]["name"])
                      : "/images/product-image-default.png";

                  return (
                    <tr key={product.id}>
                      <td>
                        <div
                          style={{
                            maxWidth: "100px",
                            margin: "auto",
                            minHeight: "100px",
                          }}
                        >
                          <img
                            className="d-block w-100"
                            src={product_image}
                            alt={`product-${product.title}`}
                          />
                        </div>
                      </td>
                      <td>
                        <Link to={`/san-pham/${product?.slug}`}>
                          <div className="btn btn-link">{capitalizeFirstOnly(product.title)}</div>
                        </Link>
                      </td>
                      <td>
                        <span>Giá lẻ: </span>
                        <span className="price-clr">
                          <NumberFormat>{product.retail_price}</NumberFormat>
                        </span>
                      </td>
                      <td>
                        <span className={`badge badge-${acceptStatusCheck(product?.accept_status).color}`}>
                          {acceptStatusCheck(product?.accept_status).text}
                        </span>
                      </td>
                      <td>
                        <Link to={`edit/${product.id}`} className="btn btn-link">Sửa</Link>
                        <span className="btn btn-link" onClick={() => deleteProduct(product.id)}>Xoá</span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">Không có sản phẩm nào</td>
                </tr>
              )}
            </tbody>
          </Table>

          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={"←"}
              nextLabel={"→"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ManageProduct;
