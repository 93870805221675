import * as Yup from 'yup';

export const useProductValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Tên sản phẩm không được để trống')
      .test('no-empty', 'Tên sản phẩm không được để trống', value => value && value.trim() !== ''),

    retail_price: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .required('Không được để trống')
      .positive('Số tiền phải là số lớn hơn 0')
      .integer('Số tiền phải là số nguyên'),

    quantity: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .required('Không được để trống')
      .positive('Số lượng phải là số lớn hơn 0')
      .integer('Số lượng phải là số nguyên'),
      promotion_start_date: Yup.date()
        .nullable()
        .transform((value, originalValue) => originalValue === "" ? null : value)
        .typeError("Ngày không hợp lệ"),
      promotion_end_date: Yup.date()
        .nullable()
        .transform((value, originalValue) => originalValue === "" ? null : value)
        .typeError("Ngày không hợp lệ")
        .min(Yup.ref('promotion_start_date'), 'Ngày kết thúc phải sau ngày bắt đầu'),
    promotion_price: Yup.number()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .positive('Giá khuyến mãi phải là số lớn hơn 0')
      .integer('Giá khuyến mãi phải là số nguyên')
      .max(Yup.ref('retail_price'), 'Giá khuyến mãi không được cao hơn giá gốc')
      .test('required-dates', 'Nếu nhập giá khuyến mãi thì phải nhập ngày bắt đầu và ngày kết thúc', function (value) {
        const { promotion_start_date, promotion_end_date } = this.parent;
        if (value !== undefined && value !== null) {
          return !!promotion_start_date && !!promotion_end_date;
        }
        return true;
      }),
  });
}
